.pasal {
    > div {
        margin-bottom: 6px;
        border-bottom: 1px solid #ddd;

        > h5 {
            padding: 10px;
            cursor: pointer;
        }

        > h5:hover {
            background: #f0f5ff;
        }

        > ul {
            > li {
                padding: 4px;
                cursor: pointer;
            }

            > li:hover {
                background: #f0f5ff;
            }
        }
    }
}

// StepOne
.stepOneCreateContract {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @include phone {
        flex-direction: column;
        align-items: flex-start;
    }

    > button {
        @include phone {
            margin-top: 10px;
            width: 100%;
        }
    }
}

.templateChoiceParent {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 8px;
}

.stepOneCreateContractClause {
    background-color: #fafafa;
    padding: 20px;
    border-radius: 4px;
    margin-top: 10px;

    @include phone {
        padding: 10px;
    }

    &__item {
        display: flex;
        align-items: center;

        @include phone {
            align-items: stretch;
            flex-direction: column;
        }

        > .clause-container {
            width: 50%;
            margin-right: 20px;

            @include phone {
                width: 100%;
                margin-right: 0;
            }
        }

        > button {
            @include phone {
                margin-top: 10px;
                width: 100%;
            }
        }
    }
}

// Assign
.assignContract {
    display: flex;
    justify-content: space-between;

    @include phone {
        flex-direction: column;
    }

    &__input {
        width: 50%;

        @include phone {
            width: 100%;
        }
    }

    &__result {
        width: 45%;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #eeeeee;
        border-style: dashed;
        padding: 10px;

        @include phone {
            width: 100%;
            margin-top: 20px;
        }
    }
}

// MODAL
.modalDetailContract {
    width: 50%;

    @include phone {
        width: 80%;
    }
}

.modalPreviewDocumentContract {
    width: 65% !important;
    margin-top: 0 !important;
    top: 16px !important;

    @include phone {
        width: 90% !important;
    }
}

.ql-toolbar.ql-snow {
    background: #f0f2f5;
}

.ql-snow .ql-picker.ql-font {
    width: 150px !important;
}

.clause-container .ql-editor,
.clause-container .ql-editor p,
.clause-container .ql-editor ol,
.clause-container .ql-editor ul,
.clause-container .ql-editor pre,
.clause-container .ql-editor blockquote,
.clause-container .ql-editor h1,
.clause-container .ql-editor h2,
.clause-container .ql-editor h3,
.clause-container .ql-editor h4,
.clause-container .ql-editor h5,
.clause-container .ql-editor h6 {
    font-family: "Times New Roman", serif;
    background: #fff;
}

.clause-container .ql-editor p,
.clause-container .ql-editor ol,
.clause-container .ql-editor ul,
.clause-container .ql-editor pre,
.clause-container .ql-editor blockquote {
    font-size: 12px;
    font-family: "Times New Roman", serif;
}

/* Set dropdown font-families */
.ql-font span[data-value="TimesNewRoman"]::before {
    font-family: "Times New Roman", serif;
    content: "Times New Roman" !important;
}

/* Set dropdown font-families */
.ql-font span[data-value="Arial"]::before {
    font-family: "ArialArialman", sans-serif;
    content: "Arial" !important;
}

/* Set content font-families */
.ql-font-Arial {
    font-family: "Arial" !important;
}
.ql-font-TimesNewRoman {
    font-family: "Times New Roman", serif !important;
}

.template_item {
    transition: ease 200ms;
    cursor: pointer;
    &:hover {
        background: #e6f7ff;
    }
}

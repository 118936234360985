.contract{
    .steps-content {
        margin-top: 16px;
        border: 1px dashed #e9e9e9;
        border-radius: 2px;
        background-color: #fafafa;
        min-height: 200px;
        text-align: start;
        padding-top: 80px;

        padding: 20px;

        justify-content: flex-start;
    }

    .steps-action {
        margin-top: 24px;
    }


    &__listContract{
        background-color: #fff;
        padding:  20px;
        
        @include phone{
            overflow-y: scroll;
        }

        &__tableWraper{
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;

            &__search{
                margin-right: 50px;

                @include phone{
                    margin-right: 10px;
                }
            }

            .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child{
                @include phone{
                    width: 120px;
                }
            }
        }
    }
}

.emptyStateContract{
    // background: #ddd;
    background: #d6e4ff;
    z-index: -0;
    width: 100%;
    height: 220px;
    opacity: 70%;
    
    @include phone{
        height: 350px;
    }

    @include tablet{
        height: 250px;
    }

    >.emptyStateWrapper{
        background: #ddd;
        z-index: 10;
        opacity: 50%;
        width: 100%;
        height: 220px;
        position: absolute;

        transition: .5s ease;
        opacity: 0;

        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        @include phone{
            height: 350px;
        }

        @include tablet{
            height: 250px;
        }

        >.buttonAddPasal{
            background-color: grey;
            color: white;
            font-size: 16px;
            padding: 16px 32px;  
            width: 200px;
            cursor: pointer;
            opacity: 1;
        }
    }
    
    >.emptyStateWrapper:hover{
        // opacity: 1;
        opacity: 70%;
    }
    
    >.formEmpty{
        margin: 10px;
        padding: 10px;
        z-index: 2;
    }

    
}
#components-layout-demo-responsive{
    height: 100vh;
}


#components-layout-demo-responsive .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}
  
.logo{
    // height: 32px;
    // background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    >img{
      width: 25%;
    }
}
.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
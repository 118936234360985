.login{
    &__left{
        background: url('../Assets/bg-login.jpg') !important;
        background-size: cover !important;
            // opacity: 0.8;
        padding: 80px !important;

        display: flex !important;
        align-items: center !important;
        flex-direction: column !important;
        justify-content: center !important;

        @include phone{
            display: none !important;
        }

        >h1{
            font-size: 40px;
            // line-height: 80px;
            color: #4F5665;

            // text-align: center;

            text-shadow: 2px 2px  #e4e4e4;
        }

        >p{
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            // text-align: center;

            color: #4F5665;
            text-transform: uppercase;
        }
    }

    &__right{
        >div{
            margin-top: 70px;
            text-align:  center;
            border-bottom: 1px solid #d9d9d9;
            width: 50%;

            >p{
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #939393;
            }
        }

        >h2{
            margin: 46px;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: #4F5665;
            text-transform: uppercase;
        }

        >.login-form{
            width: 56%;
            margin-bottom: 100px;

            .login-form-button{
                width: 100%;
            }
        }
    }
}
.home {
    .home-section {
        padding: 0 16px;
    }

    .card {
        padding: 16px;
        min-height: 160px;
        color: #fff;
        text-align: center;
        border-radius: 12px;

        h3 {
            color: #fff !important;
        }
    }

    .pie-chart {
        > div {
            text-align: center;

            > h5 {
                margin-bottom: 16px;
            }

            > p {
                font-size: 14px;
            }
        }

        .my-pretty-chart-container {
            background: #fff;
            border-radius: 10px;

            rect {
                width: auto !important;
            }
        }
    }

    .documentType_header {
        background: #fff;
        margin-bottom: 16px;
        padding: 16px;
        border-radius: 12px;
    }

    .barchart_container {
        background: #fff;
        padding: 16px;
        border-radius: 12px;
        height: 100%;
    }

    .barchart_footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        margin-top: 32px;

        p {
            margin: 0;
            color: rgb(61, 61, 61);
        }

        h4 {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
}
